<template>
  <div class="side-page">
    <router-link to="/">
      <img class="header" alt="John Cunningham Header" src="/header/white-clear.png" />
    </router-link>
    <div class="card">
      <h1 class="margin-20">Work</h1>
      <p class="margin-20">I am currently employed by American Century Investments, where I work as a Software Engineer Specialist. While I started working for the firm as an intern in the Summer of 2020, I have worked for American Century Investments full-time since January 2021. In my role, I've used machine learning and cloud services to create products that help ACI put clients first.</p>
      <p class="margin-20">For my full work history, refer to my LinkedIn.</p>
      <a href="https://www.linkedin.com/in/cunningham1212/" target="_blank" rel="noopener noreferrer">
        <div id="main-link">
          <p>LinkedIn</p>
          <i class="bi bi-arrow-right"></i>
        </div>
      </a>
    </div>
    <a target="_blank" rel="noopener noreferrer" id="continue-link" href="https://calendly.com/cunningham-code/15mincall">Continue to Contact <i class="bi bi-arrow-right"></i></a>
  </div>
</template>
